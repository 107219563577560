import React, { useState } from "react";
import Stars from "../../reviews/Customreviews/Stars";



function Faq() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
            UserName: "Steve A.",
            Date: "May 31, 2022",
            Rate: 5,
            Description: "I used Yelp to look for another auto detailer as my normal detailer is no longer in the business. Izzy was very responsive and was priced right. He showed up a few minutes late, but let me know he was running late. The job in the end was really good. I'll definitely be using him in the future.",
            red: "Yelp",
            enlace: "https://www.yelp.com/user_reviews?userid=66329",
            ima: "https://imagen.com/stevea"
        },
        {
            UserName: "Jason & Carmen",
            Date: "Dec 19, 2023",
            Rate: 5,
            Description: "Couldn’t Be Happier The crew did an amazing job on our home and fireplace! They were reliable and professional. They incorporated my ideas and used their experience to improve on them. The results are stunning and we could not be more pleased. I would recommend them to anyone!",
           
        },
        {
            UserName: "Doug",
            Date: "Dec 6, 2023",
            Rate: 5,
            Description: "Fireplace Thanks again Cruz Masonry. They did a great job putting my hearth and fireplace back together after the plumber replaced a bad gas valve. I will definitely use them again.",
           
        
        },
        {
            UserName: "Doug",
            Date: "Nov 12, 2023",
            Rate: 5,
            Description: "Chimney Repair Cruz Masonry did a great job repairing our chimney and the inside of our fireplace. They were on time, charged a fair rate, and great to work with. I would definitely recommend them.",
           
        },
        {
            UserName: "Fred H.",
            Date: "Sep 2, 2023",
            Rate: 5,
            Description: "Great Work Armando did a great job repairing the stone on our front porch. He arrived as agreed, completed the work and left the area clean and neat. I’m more than pleased with his work, and I highly recommend Armando.",
           
        },
        {
            UserName: "James B",
            Date: "Aug 18, 2023",
            Rate: 5,
            Description: "Great Job And Service! Armando did a great job of patching the brick work on our house, and adding a new sidewalk along the side of our home. He was meticulous in his work, courteous, and professional. My wife and I would highly recommend his services.",
           
        },
        {
            UserName: "Brooklyn Shelton",
            Date: "Jul 27, 2023",
            Rate: 5,
            Description: "Happy Customer! What a great company! Polite, punctual, and very knowledgeable, these masonry specialists performed a timely and professional brick wall repair service. I'm very pleased with the result and absolutely recommend this reputable masonry company to anyone! Thank you!",
           
        }
    ]




    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto text-black">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item?.UserName} </span>
                                        <Stars score={item?.Rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item?.Date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item?.Description}</ReadMore>
                            </div>
                            {/* <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item?.ima} alt="agni" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item?.enlace}>{item?.red}</a>
                                </div>
                            </div> */}
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;