import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import BaseLayout from "../components/global/BaseLayout";
import ValuesOne from "../components/values/Values_4";
import BlockPrincipal from "../components/block/Block_6";
import BlockSecondary from "../components/block/Block_3";

function About() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="About"
          Subheader="About"
          bgimg={`${rpdata?.stock?.[6]}`}
        />
        <BlockPrincipal
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image1={rpdata?.gallery?.length > 3 ? rpdata?.gallery?.[3] : rpdata?.stock?.[3]}
          image2={rpdata?.gallery?.length > 4 ? rpdata?.gallery?.[4] : rpdata?.stock?.[4]}
          image3={rpdata?.gallery?.length > 5 ? rpdata?.gallery?.[5] : rpdata?.stock?.[5]}
          image4={rpdata?.gallery?.length > 6 ? rpdata?.gallery?.[6] : rpdata?.stock?.[6]}
          corte={rpdata?.stock}
          listsAbout
        />
        <ValuesOne
          image={rpdata?.gallery?.length > 13 ? rpdata?.gallery?.[13] : rpdata?.stock?.[13]}
          image1={rpdata?.gallery?.length > 14 ? rpdata?.gallery?.[14] : rpdata?.stock?.[14]}
          image2={rpdata?.gallery?.length > 15 ? rpdata?.gallery?.[15] : rpdata?.stock?.[15]}
        />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[8]?.slogan}
          text={rpdata?.dbAbout?.[1]?.text}
          image={rpdata?.gallery?.length > 16 ? rpdata?.gallery?.[16] : rpdata?.stock?.[16]}
        />
      </div>
    </BaseLayout>
  );
}

export default About;
